import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import tw from 'tailwind.macro';
import {
  FaArrowsAlt,
  FaBalanceScale,
  FaBed,
  FaBriefcaseMedical,
  FaChartPie,
  FaCity,
  FaCog,
  FaDove,
  FaGraduationCap,
  FaHandHoldingUsd,
  FaLandmark,
  FaLeaf,
  FaPeopleCarry,
  FaRegChartBar,
  FaTabletAlt,
  FaTractor,
} from 'react-icons/fa';
import Button from '../components/button';
import Grid from '../components/grid';
import Layout from '../components/layout';
import LeadText from '../components/leadText';
import Masthead from '../components/masthead';
import Section from '../components/section';
import SEO from '../components/seo';
import PageNavigator from '../components/pageNavigator';
import Panel from '../components/panel';
import SafeLink from '../components/safeLink';

const Conference2024 = ({ data, location }) => {
  const parentTitle =
    'The Centre for Social Justice presents:';
  const pageTitle =
    'The 5th International Social Justice Conference';

  return (
    <Layout location={location} parentTitle={parentTitle} pageTitle={pageTitle}>
      <SEO title={pageTitle} image={data.hero.childImageSharp.fluid.src} />
      <Masthead
        parentTitle={parentTitle}
        pageTitle={pageTitle}
        backgroundImage={data.hero.childImageSharp.fluid}
        styling={[tw`bg-maroon`, `min-height:30vh;`]}
      />
      <PageNavigator
        items={{
          welcome: 'Welcome',
          concept: 'Concept',
          abstracts: 'Abstracts',
          programme: 'Programme',
          media: 'Media Coverage',
          //registration: 'Registration',
          watch: 'Watch',
          pledges: 'Statement & Pledges',
          //   /* press: 'Press coverage', */
          }}
          buttonText="Read media statement"
          buttonLink="/blog/2024/10/participants-pledge-united-action-against-hunger-at-international-social-justice-conference-and-summit"      
        />
      {/* <div className="container" css={tw`mt-10 px-10 py-5 rounded-lg border-t-8 border-gold bg-white shadow-lg`}>
        <LeadText css={tw`mb-0`}>Nominations for the 2024 Social Justice Champion Awards are now open! <SafeLink to="/downloads/events/2024-08-social-justice-champion-awards.pdf" css={tw`border-b-2 border-maroon hover:text-maroon`}>Click here to submit nominations.</SafeLink></LeadText>
      </div> */}
      <Section id="welcome">
        <Img
          fluid={data.Conference2024Banner.childImageSharp.fluid}
          alt="The 5th International Social Justice Conference, 16-17 October 2024"
          css={tw`w-full`}
        />
        <hr css={tw`mt-3 mb-10`} />
        <h2>
        Welcome!
        </h2>                       
        {/*
        <Button
          to="https://qkt.io/QICEvA "
          size="md"
          css={tw`mb-2 mr-2 bg-dark-gray`}
        >
          Pay your ticket
        </Button> 
        <Button
          to="/downloads/events/2024-10-call-for-papers.pdf"
          size="md"
          css={tw`mb-2 mr-2`}
        >
          Download call for abstracts
        </Button>
        <Button
          to="/downloads/events/2024-10-call-for-papers.pdf"
          size="md"
          css={tw`mb-2 mr-2`}
        >
          Download call for abstracts
        </Button>*/}      
        <Button
          to="/blog/2024/10/participants-pledge-united-action-against-hunger-at-international-social-justice-conference-and-summit"
          size="md"
          css={tw`mb-2 mr-2 bg-dark-gray`}
        >
          Read media statement
        </Button> <Button
          to="/blog/2024/11/cape-town-statement-adopted-at-the-5th-social-justice-conference"
          size="md"
          css={tw`mb-2 mr-2`}
        >
          Read statement and pledges
        </Button>
        <br/>       
        <p>The 5th International Social Justice Conference will take place 16-17 October 2024 at the Artscape Theatre Centre, Cape Town, South Africa.</p>       
      </Section>      
      <Section id="concept" isShaded>
        <h2>Concept</h2>
        <p>The International Conference on Social Justice and The Sustainable Development Goal on Zero Hunger aims to interrogate research, policies and legal developments on hunger with a view to helping the pace of progress in addressing hunger as one of the United Nations Sustainable Development Goals 2 (“Zero Hunger”). The International Conference will bring together academics, lawyers, judges, civil society researchers who are involved on research on the intersection between hunger, social justice and human rights; to share research outputs, explore innovative solutions, best practices on the integration of data science and neuroscience in social and legal policy design processes.</p>
        <Button
          to="/downloads/events/2024-07-conference-concept-note.pdf"
          size="md"
          css={tw`mb-2 mr-2 bg-dark-gray`}
        >
          Download concept note
        </Button>
      </Section>
      <Section id="abstracts">
        <h2>Abstracts</h2>
        <p>You can view and download all accepted abstracts using the link below.</p>
        <Button
          to="https://drive.google.com/drive/folders/1GWR2BTJwQ2kRlWoenWSFRCdRU9WjNFbP?usp=share_link"
          size="lg"
          css={tw`mb-2 mr-2`}
        >
          See abstracts
        </Button>
      </Section>
      {/* <Section id="registration" isShaded>
        <h2>Registration</h2>
        <p>You can now buy tickets to attend the conference.</p>
        <Button
          to="https://www.quicket.co.za/events/269646-5th-international-social-justice-conference-and-6th-annual-social-justice-summit#/"
          size="lg"
          css={tw`mb-2 mr-2`}
        >
          Buy tickets
        </Button>
      </Section> */}
     {
      /*<Section id="resources" isShaded>
        <h2>Presenter resources</h2>
        <p>The call for abstracts is now closed. Please note the following important dates:</p>
        <ul>
          <li>Deadline for submission of PowerPoint Presentations to fanele@sun.ac.za: 09 October 2024</li>
          <li>Deadline for submission of the draft paper: 11 October 2024</li>
          <li>Deadline for submission of the final paper: 15 November 2024</li>
          <li>Deadline for review and approval of the final paper by the Organising Committee: 30 November 2024</li>
        </ul>
        <br/>
        <Button
          to="/downloads/events/2024-07-SocialJustice_InternationalConference&Summit_Paper&PresentationGuidelines_03July2024versionF.pdf"
          size="md"
          css={tw`mb-2 mr-2`}
        >
          Download guidelines
        </Button> <Button
          to="/downloads/events/conference-summit-2024-poster-tips-20240724.pdf"
          size="md"
          css={tw`mb-2 mr-2 bg-dark-gray`}
        >
          Download poster tips
        </Button> <Button
          to="/downloads/events/conference-summit-2024-ppt-template.pptx"
          size="md"
          css={tw`mb-2 mr-2 bg-dark-gray`}
        >
          Download presentation template
        </Button>  
      </Section>      
      <Section id="statements" isShaded>
        <h2>Summit and conference outputs</h2>        
        <ul>          
        <li><SafeLink to="/downloads/events/2024-10-cape-town-declaration.pdf" css={tw`border-b-2 border-maroon hover:text-maroon`}>Download the Cape Town Declaration on The Role of Business and Civil Society in Advancing
Social Justice</SafeLink>, adopted at the 4th International Conference on Social Justice on 11 October 2024</li>
          <li><SafeLink to="/downloads/events/2024-10-cape-town-statement.pdf" css={tw`border-b-2 border-maroon hover:text-maroon`}>Download the Cape Town Statement on The Role of Business and Civil Society in Advancing
Social Justice</SafeLink>, adopted at the 5th Social Justice Summit on 12 October 2024</li>
        </ul>
      </Section>*/}
      <Section id="programme" isShaded>
        <h2>Programme</h2>                   
        <LeadText>Download the full conference programme below.</LeadText>
        <Button
          to="/downloads/events/2024-10-conference-programme.pdf"
          size="lg"
          css={tw`mb-2 mr-2`}
        >
          Download programme (13MB)
        </Button>
      </Section>
      <Section id="media">
        <h2>Media Coverage and more</h2>        
        <ul>          
          <li><SafeLink to="/blog/2024/10/participants-pledge-united-action-against-hunger-at-international-social-justice-conference-and-summit" css={tw`border-b-2 border-maroon hover:text-maroon`}>Official media statement</SafeLink></li>
          <li><SafeLink to="https://www.universityworldnews.com/post.php?story=2024102207030187" css={tw`border-b-2 border-maroon hover:text-maroon`}>Coverage by World University News</SafeLink></li>
          <li><SafeLink to="https://foodsecurity.ac.za/news/join-us-at-the-5th-international-social-justice-conference/" css={tw`border-b-2 border-maroon hover:text-maroon`}>Coverage by Centre of Excellence in Food Security</SafeLink></li>
        </ul>
      </Section>
      <Section id="watch" isShaded>
        <h2>Watch</h2>
        <LeadText>Couldn't make it to the Conference? You can watch recordings of the 2024 Conference and Summit below:</LeadText>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/videoseries?si=jdygiIHUn4Wvlq8B&amp;list=PLwTL48ihN7WZ-If-jW-wMFsU_8ZRwL_Yp"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowFullScreen
          title="YouTube video player"
          css={tw`my-10 max-w-full`}
        />
        <LeadText>
          You can watch free recordings of previous summits and conferences on our website.      
          <ul>          
            <li><SafeLink to="/summit-2020/" css={tw`border-b-2 border-maroon hover:text-maroon`}>Watch the 2020 summit &rarr;</SafeLink></li>
            <li><SafeLink to="/summit-conference-2021/" css={tw`border-b-2 border-maroon hover:text-maroon`}>Watch the 2021 summit and conference &rarr;</SafeLink></li>
            <li><SafeLink to="/summit-conference-2022/" css={tw`border-b-2 border-maroon hover:text-maroon`}>Watch the 2022 summit and conference &rarr;</SafeLink></li>
            <li><SafeLink to="/conference-summit-2023/" css={tw`border-b-2 border-maroon hover:text-maroon`}>Watch the 2023 summit and conference &rarr;</SafeLink></li>
          </ul>
        </LeadText>           
      </Section>        
      <Section id="pledges">
        <h2>Statement and Pledges</h2>                   
        <Button
          to="/downloads/events/2024-11-conference-resolution.pdf"
          size="lg"
          css={tw`mb-2 mr-2`}
        >
          Download Statement and Pledges (2MB)
        </Button>
      </Section>             
    </Layout>
  );
};

export default Conference2024;

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "2024-08-conference-bg.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Conference2024Banner: file(relativePath: { eq: "2024-08-conference-poster.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }    
    site {
      siteMetadata {
        title
      }
    }
  }
`;
